<template>
  <div class="main-content">
    <h3 class="page-heading">Payout Statement</h3>
    <section>
      <Card :flex="12" title="Invoice Period" class="statement__wrapper">
        <template v-slot:content>
          <v-select
            :options="periods"
            v-model="selectedPeriod"
            :clearable="false"
            class="scroller" />
        </template>
      </Card>
    </section>
    <StatementPayoutTable
      v-if="!loadingPayoutStatementData"
      :loadingPayoutPeriods="loadingPayoutStatementData"
      :invoicePeriod="selectedPeriod">
    </StatementPayoutTable>
  </div>
</template>

<script>
  import store from "@/store";
  import Card from "@/components/Card.vue";
  import StatementPayoutTable from "@/components/Statements/Payouts/StatementPayoutTable.vue";

  export default {
    data() {
      return {
        periods: [],
        selectedPeriod: null,
        operatorId: parseInt(this.$route.params.operatorId),
        loadingPayoutStatementData: false,
      };
    },
    components: {
      Card,
      StatementPayoutTable,
    },

    async beforeMount() {
      this.loadingPayoutStatementData = true;
      await this.getVendorInvoicingPeriods();
      this.loadingPayoutStatementData = false;
    },
    methods: {
      async getVendorInvoicingPeriods() {
        var apiPeriods =
          await store.state.apiPrivate.client.endpoints.invoicePeriod.getWithQuery(
            {
              vendors: [this.operatorId],
            }
          );
        this.periods = apiPeriods.data.data.map((x) => ({
          ...x,
          label:
            this.formatDate(x.startDate) + " - " + this.formatDate(x.endDate),
        }));

        // If we've got any periods, then select the first.
        this.selectedPeriod = this.periods.length > 0 ? this.periods[0] : null;
      },
    },
  };
</script>
