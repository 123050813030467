<template>
  <div class="section-wrapper row align-items-center full-width">
    <div class="centered-loading-wrapper column" v-if="loadingData">
      <LoadingSpinner />
      <p>Loading Data...</p>
    </div>
    <div v-else-if="!this.statementAvailable || !this.data?.statement">
      No statement data available for this period.
    </div>
    <div v-else>
      <!-- Display the table -->
      <div class="data-table">
        <div v-for="item in colDefs" :key="item.name" class="data-table__row">
          <div class="data-table__cell">{{ item.headerName }}</div>
          <input type="checkbox" :id="item.headerName" />
          <div class="row">
            <label
              class="data-table__cell"
              :for="item.headerName"
              :class="{ 'data-table__cell_expanded': item.children }"
              >{{ formatMoney(data.statement[item.field]) }}
              <Icon
                v-if="item.children && item.children.data.length"
                :path="mdiChevronDown"
                :size="20"
                class="data-table__icon" />
            </label>
          </div>
          <AgGridVue
            v-if="item.children && item.children.data.length"
            :style="{
              width: 100 + '%',
              height: (160 * item.children.data.length) / 2 + 'px',
            }"
            class="ag-theme-alpine data-table__content"
            :columnDefs="item.children.col"
            :rowData="item.children.data">
          </AgGridVue>
        </div>
      </div>
      <section>
        <Card :flex="12" title="Documents" class="invoice__documents">
          <template v-slot:content>
            <div
              class="centered-loading-wrapper column"
              v-if="loadingDocuments">
              <LoadingSpinner />
              <p>Loading Documents...</p>
            </div>
            <table v-else>
              <tr v-for="document in documents" :key="document.guid" class="">
                <td>
                  <Icon :path="mdiFilePdfBox" :size="20" />
                </td>
                <td class="reference">
                  <!-- this is not quite right, receipts does the same thing though -->
                  <a class="document__link" :href="downloadLink(document.guid)">
                    {{ document.reference }}
                  </a>
                </td>
                <td class="issued__on">(issued on: {{ document.issuedOn }})</td>
                <td v-if="document.void">** VOID **</td>
              </tr>
            </table>
          </template>
        </Card>
      </section>
    </div>
  </div>
</template>

<script>
  import LoadingSpinner from "@/components/LoadingSpinner";
  import store from "@/store";
  import { mdiChevronDown } from "@mdi/js";
  import Card from "@/components/Card.vue";
  import { mdiFilePdfBox } from "@mdi/js";

  import { AgGridVue } from "ag-grid-vue3";

  export default {
    components: {
      LoadingSpinner,
      Card,
      AgGridVue,
    },
    props: {
      invoicePeriod: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        mdiChevronDown,
        mdiFilePdfBox,
        data: null,
        documents: [],
        loadingData: false,
        loadingDocuments: false,
        statementAvailable: false,
        documentsAvailable: false,
        operatorId: parseInt(this.$route.params.operatorId),
        baseColumns: [
          {
            headerName: "Gross Vendor Revenue",
            field: "revenue",
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Cancellations",
            field: "cancellationsTotal",
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Ad-hoc Revenue Adjustments",
            field: "adhocRevenueAdjustments",
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Adjusted Vendor Revenue",
            field: "adjustedTotal",
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Commission Payout",
            field: "commissionTotal",
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Walk Up Transaction Fee",
            field: "processingFeeWalkup",
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Ad-hoc Payout Adjustment",
            field: "adhocPayoutAdjustments",
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Vendor Cancellation Fee",
            field: "refunds",
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Refunds",
            field: "refunds",
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Vendor Payout",
            field: "vendorPayout",
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
        ],
        columnDefs: null,
      };
    },
    watch: {
      async invoicePeriod() {
        this.data = null;
        this.loadingData = true;
        this.loadingDocuments = true;
        await this.loadPayoutStatement();
        await this.loadInvoiceDocuments();
        this.loadingData = false;
        this.loadingDocuments = false;
      },
    },
    async mounted() {
      this.loadingData = true;
      this.loadingDocuments = true;
      await this.loadPayoutStatement();
      await this.loadInvoiceDocuments();
      this.loadingData = false;
      this.loadingDocuments = false;
    },
    methods: {
      downloadLink(code) {
        return (
          "https://" +
          store.state.apiPrivate.client.domain +
          "/api/v1/invoices/" +
          code +
          "/download"
        );
      },
      async loadPayoutStatement() {
        var apiData =
          await store.state.apiPrivate.client.endpoints.periodStatements.getVendorPeriodStatementbyId(
            this.invoicePeriod.id,
            this.operatorId
          );

        this.data = apiData.data.data;
        this.statementAvailable = this.data.statement != null;
      },
      async loadInvoiceDocuments() {
        var apiData =
          await store.state.apiPrivate.client.endpoints.invoiceVendor.getHeadersforPeriod(
            this.operatorId,
            this.invoicePeriod.id
          );

        this.documents = apiData.data.data;
        this.documentsAvailable = this.documents != null;
      },
      getColumnDefinitions() {
        let columns = this.baseColumns;

        columns[
          this.baseColumns.map((object) => object.field).indexOf("revenue")
        ].children = this.getRevenueGroupedData();

        columns[
          this.baseColumns
            .map((object) => object.field)
            .indexOf("cancellationsTotal")
        ].children = this.getCancellationsGroupedData();

        columns[
          this.baseColumns
            .map((object) => object.field)
            .indexOf("adhocRevenueAdjustments")
        ].children = this.getAdhocRevenueAdjustmentsData();

        columns[
          this.baseColumns
            .map((object) => object.field)
            .indexOf("adhocPayoutAdjustments")
        ].children = this.getAdhocPayoutAdjustmentsData();

        columns[
          this.baseColumns
            .map((object) => object.field)
            .indexOf("commissionTotal")
        ].children = this.getCommissionsData();

        return columns;
      },
      getRevenueGroupedData() {
        return {
          col: [
            {
              headerName: "Type",
              field: "type",
              width: 200,
            },
            {
              headerName: "Total",
              field: "total",
              width: 150,
            },
            {
              headerName: "Cash",
              field: "cash",
              width: 150,
              valueFormatter: (params) => {
                return params.value ? params.value : "£0.00";
              },
            },
            {
              headerName: "Card",
              field: "card",
              width: 150,
            },
            {
              headerName: "Card Manual",
              field: "cardManual",
              width: 150,
              valueFormatter: (params) => {
                return params.value ? params.value : "£0.00";
              },
            },
            {
              headerName: "Promo",
              field: "promo",
              width: 150,
            },
          ],
          data: [
            {
              type: "Walk Up",
              total: this.formatMoney(this.data.statement["revenueWalkup"]),
              cash: this.formatMoney(this.data.statement["revenueWalkupCash"]),
              card: this.formatMoney(this.data.statement["revenueWalkupCard"]),
              cardManual: this.formatMoney(
                this.data.statement["revenueWalkupCardManual"]
              ),
              promo: this.formatMoney(
                this.data.statement["revenueWalkupPromotion"]
              ),
            },
            {
              type: "Online",
              total: this.formatMoney(this.data.statement["revenueOnline"]),
              cash: this.formatMoney(this.data.statement["revenueOnlineCash"]),
              card: this.formatMoney(this.data.statement["revenueOnlineCard"]),
              cardManual: this.formatMoney(
                this.data.statement["revenueOnlineCardManual"]
              ),
              promo: this.formatMoney(
                this.data.statement["revenueOnlinePromotion"]
              ),
            },
          ],
        };
      },
      getCancellationsGroupedData() {
        return {
          col: [
            {
              headerName: "Type",
              field: "type",
              width: 200,
            },
            {
              headerName: "Total",
              field: "total",
              width: 500,
            },
          ],
          data: [
            {
              type: "By Customer",
              total: this.formatMoney(
                this.data.statement["customerCancellations"]
              ),
            },
            {
              type: "By Vendor",
              total: this.formatMoney(
                this.data.statement["vendorCancellations"]
              ),
            },
          ],
        };
      },
      getNamedAdjustmentAmountColumns() {
        return [
          {
            headerName: "Adjustment",
            field: "name",
            width: 250,
          },
          {
            headerName: "Description",
            field: "description",
            width: 400,
          },
          {
            headerName: "Cost",
            field: "amount",
            width: 150,
            valueFormatter: (params) => {
              return params.value ? this.formatMoney(params.value) : "£0.00";
            },
          },
        ];
      },
      getAdhocRevenueAdjustmentsData() {
        return {
          col: this.getNamedAdjustmentAmountColumns(),
          data: this.data.adhocRevenueAdjustments,
        };
      },
      getAdhocPayoutAdjustmentsData() {
        return {
          col: this.getNamedAdjustmentAmountColumns(),
          data: this.data.adhocPayoutAdjustments,
        };
      },
      getCommissionsData() {
        return {
          col: [
            {
              headerName: "Partner",
              field: "name",
              width: 250,
            },
            {
              headerName: "Arrangement",
              field: "description",
              width: 400,
            },
            {
              headerName: "Amount",
              field: "amount",
              width: 150,
              valueFormatter: (params) => {
                return params.value ? this.formatMoney(params.value) : "£0.00";
              },
            },
          ],
          data: this.data.commissions,
        };
      },
    },
    computed: {
      colDefs() {
        return this.getColumnDefinitions();
      },
    },
  };
</script>

<style lang="scss">
  .invoice__documents {
    margin-top: 20px;

    .document__link:hover {
      color: $col_beta;
      cursor: pointer;
    }

    .reference {
      font-weight: 800;
    }
    .issued__on {
      font-size: small;
      font-style: italic;
      color: $col_beta;
    }
  }
  .data-table {
    min-width: 800px;
    width: 100%;
    margin-top: 20px;

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
      margin: 0;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0;
      justify-content: space-between;
      border-bottom: 1px solid $col_faded;
      overflow: hidden;
    }

    &__icon {
      margin-left: 7px;
    }

    &__cell {
      width: 30%;
      font-weight: bold;

      &_expanded {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }

    &__content {
      max-height: 0;
      padding: 0 1em;
      margin: 0 !important;
      background: white;
      transition: all 0.35s;
    }

    // :checked
    input:checked {
      + .data-table__cell_expanded {
        &::after {
          transform: rotate(90deg);
        }
      }
      ~ .data-table__content {
        max-height: 100vh;
        padding: 1em;
      }
    }
  }

  .ag-theme-alpine .ag-header {
    width: max-content;
  }
</style>
